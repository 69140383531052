/**
 * Created by ndrai on 01/06/2017.
 */
import {
    typeFilterAreas,
    typeFilterLabels,
    typeFilterSegments,
    typeFilterTags
} from '../../search/components/search-treks-filter/search-treks-filter';

export const searchFilterSaveKey = 'search-filter';
export const searchQuerySaveKey = 'search-query';
const defaultLocale = 'en';
const restoredFilter = JSON.parse(sessionStorage.getItem(searchFilterSaveKey)!);
const restoredQuery = JSON.parse(sessionStorage.getItem(searchQuerySaveKey)!);

export const resetFilterType = 'reset';
export const defaultFilterState = {
    [typeFilterAreas]: [],
    [typeFilterSegments]: [],
    [typeFilterTags]: [],
    [typeFilterLabels]: []
    // [typeFilterDifficulty]: {
    //     model: null, //0, //sliderDifficulty.model,
    //     modelMax: null // 10 //sliderDifficulty.modelMax
    // },
    // [typeSliderLength]: {
    //     model: null, //sliderLength.model,
    //     modelMax: null // sliderLength.modelMax
    // },
    // [typeSliderDuration]: {
    //     model: null, //sliderDuration.model,
    //     modelMax: null // sliderDuration.modelMax
    // }
};
